<template>
  <div v-if="isShowing">
    <template v-if="isLargeBreakpoint">
      <div class="highlight-banner">
        <div class="content">
          <span class="highlight mr-4">{{ t(`${countryLocale}.highlight`) }}</span>
          <span>{{ t(`${countryLocale}.text`) }}</span>
          <ZButton
            variant="white"
            size="sm"
            class="cta ml-3"
            aria-label="banner-cta"
            @click="applySearchFilter(true)"
          >
            <fa
              :icon="['fal', 'magnifying-glass']"
              class="mr-2"
            />
            {{ t(`${countryLocale}.cta`) }}
          </ZButton>
        </div>
        <div class="close">
          <ZClose @click="handleDismiss" />
        </div>
      </div>
    </template>

    <template v-else>
      <ClientOnly>
        <Teleport
          to="#teleport-target"
        >
          <OverlayDrawer
            visible
            no-card-body
            hide-header
            class="highlight-banner-mobile"
            @close="handleDismiss"
          >
            <div class="header">
              <div class="d-flex justify-content-end close">
                <ZClose @click="handleDismiss" />
              </div>

              <div class="text-center mt-2">
                <img
                  :src="bannerImage"
                  :alt="bannerAlt"
                  loading="lazy"
                >
              </div>
            </div>

            <div class="body">
              <div class="content">
                <strong class="highlight mr-4">{{ t(`${countryLocale}.highlight`) }}</strong>
                <div class="text mt-3">
                  {{ t(`${countryLocale}.text`) }}
                </div>
                <div class="mt-4">
                  <ZButton
                    outline
                    block
                    size="sm"
                    class="cta"
                    aria-label="banner-cta"
                    @click="applySearchFilter(true)"
                  >
                    <fa
                      :icon="['fal', 'magnifying-glass']"
                      class="mr-2"
                    />
                    {{ t(`${countryLocale}.cta`) }}
                  </ZButton>
                </div>
              </div>
            </div>
          </OverlayDrawer>
        </Teleport>
      </ClientOnly>
    </template>
  </div>
</template>

<script setup lang="ts">
import { COOKIES } from '~/constants'
import { THIRTY_DAYS_IN_SECONDS } from '~/constants/durations'

const runtimeConfig = useRuntimeConfig()
const { $search } = useNuxtApp()
const { isDesktop } = useDevice()
const { isWebView } = usePlatform()
const { isInUs } = useGeolocation()
const { isLargeBreakpoint } = useBreakpoint()
const { t } = useI18n()
const { trackBannerClicked } = useTracking()
const { getOs } = useOs()
const { routeBaseName } = useBaseName()
const route = useRoute()

const VERSION = 1

const countryLocale = computed(() => isInUs.value ? 'us' : 'canada')
const cookieName = computed(() => `${COOKIES.highlightBanner}-${VERSION}`)
const bannerCookie = useCookie(cookieName.value, {
  default: () => '0',
  maxAge: THIRTY_DAYS_IN_SECONDS,
})
const isShowing = computed(() => !Number(bannerCookie.value) && !isWebView.value)
const bannerImage = computed(() => {
  const path = `${runtimeConfig.public.staticAssetsURL}/web/images/custom-icons/highlight-banner/`
  return isInUs.value ? `${path}delivery-truck.svg` : `${path}unlock-rv-calendar.svg`
})
const bannerAlt = computed(() => isInUs.value ? 'Delivery truck' : 'Unlock RV calendar')

function handleDismiss() {
  bannerCookie.value = '1'
}

function trackBanner() {
  trackBannerClicked({
    deviceType: isDesktop ? 'desktop' : 'mobile',
    os: getOs(),
    userCountry: isInUs.value ? 'US' : 'CA',
    bannerName: isInUs.value ? 'delivery' : 'midweek',
    pageSource: routeBaseName.value,
  })
}

function applySearchFilter(isDismiss = false) {
  const start = route.query?.StartDate as string
  const end = route.query?.EndDate as string

  const result = selectMidweekDates(start, end)

  if (isInUs.value) {
    $search.updateFilters({ delivery: true })
  }
  else {
    $search.updateDates({ start: result.start, end: result.end })
  }

  if (isDismiss) {
    handleDismiss()
  }

  trackBanner()
}
</script>

<style lang="scss" scoped>
.close {
  margin-left: auto;

  .zcircle-button {
    transform: scale(0.75);
  }
}

.highlight-banner {
  background-color: getColor('highlight-600');
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 1rem 1.5rem;
  margin-top: 0.75rem;

  .content {
    font-size: 0.875rem;

    span.highlight {
      font-weight: 600;
      color: #f6cf00;
      font-size: 1rem;
    }

    .cta {
      font-weight: normal;
    }
  }
}

.highlight-banner-mobile {
  .header,
  .body {
    padding: 1rem 1.5rem;
  }

  .header {
    background-color: getColor('highlight-100');

    .close {
      margin-right: -0.5rem;
      margin-top: -0.5rem;
    }
  }

  .body {
    margin: 1rem 0;

    .text {
      @include captionBase;
      color: getColor('primary-350');
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "us": {
      "highlight": "Save 40% 💰",
      "text": "Get your RV delivered to your campsite and save up to 40% on insurance packages!",
      "cta": "View deliverable RVs"
    },
    "canada": {
      "highlight": "Unlock more RVs 🔓",
      "text": "Find your perfect RV! More choices available midweek.",
      "cta": "View more RVs"
    }
  },
  "fr": {
    "us": {
      "highlight": "Économisez 40% 💰",
      "text": "Faites livrer votre VR et économisez 40% sur la protection !",
      "cta": "Voir les VR livrables"
    },
    "canada": {
      "highlight": "Disponibilité limitée !",
      "text": "Découvrez des pépites cachées en réservant du lundi au vendredi !",
      "cta": "Voir plus de VRs"
    }
  }
}
</i18n>
