<template>
  <div>
    <div
      ref="map"
      class="map"
    />

    <div
      v-if="showSearchOnMove"
      class="search-move-map-btn"
    >
      <ZButton
        v-if="!searchOnMapMove && boundsChanged"
        variant="white"
        @click="boundsUpdated(true)"
      >
        {{ t('searchThisArea') }}
      </ZButton>

      <ZFormCheckbox
        v-else
        :model-value="searchOnMapMove"
        container-classes="search-checkbox"
        @update:model-value="toggleSearchOnMap()"
      >
        {{ t('searchAfterMapMove') }}
      </ZFormCheckbox>
    </div>

    <SearchMapZoomButtons
      v-if="showZoomControls"
      :current-zoom="currentZoom"
      :min-zoom="minZoom"
      :max-zoom="maxZoom"
      @zoom:in="zoomIn"
      @zoom:out="zoomOut"
    />

    <div
      v-show="openedMarker.active"
      ref="popupEl"
      class="popup"
    >
      <template v-if="openedMarker.marker">
        <LazyCardRv
          :key="openedMarker.marker.Item.Id"
          :rv="openedMarker.marker.Item"
          :search-request-id="openedMarker.marker.Item.SearchId"
          :page-size="items?.length ?? 0"
          cta="map"
          track-visiblity
        />
      </template>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import rvSecondary from '~/assets/images/marker-rv-blue.svg?raw'
import { throttle } from '~/lib/useUtils'

export default {
  props: {
    shouldMount: {
      type: Boolean,
      default: false,
    },

    hoveredId: {
      type: String,
      default: null,
    },

    maxZoom: {
      type: Number,
      default: 14,
    },

    defaultZoom: {
      type: Number,
      default: 8,
    },

    minZoom: {
      type: Number,
      default: 4,
    },

    items: {
      type: Array,
      default: () => [],
    },

    showMarkerIcon: {
      type: Boolean,
      default: false,
    },

    boundingBox: {
      type: Object,
      default: null,
    },

    centerPoint: {
      type: Object,
      default: () => ({ lat: 47.434275, lng: -98.7856237 }),
    },

    showZoomControls: {
      type: Boolean,
      default: true,
    },

    showSearchOnMove: {
      type: Boolean,
      default: true,
    },

    updateSearchOnMapMove: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['bounds-updated', 'map-initialized'],

  setup() {
    const { t } = useI18n({
      scope: 'local',
    })

    const { priceDisplay } = usePrice()

    return {
      t,
      priceDisplay,
    }
  },

  data() {
    return {
      map: false,
      popper: null,

      openedMarker: {
        marker: null,
        active: false,
      },

      hoveredMarkerId: null,

      markers: {},
      searchOnMapMove: this.updateSearchOnMapMove,
      boundsChanged: false,
      lastBounds: {},
    }
  },

  computed: {
    gestureHandling() {
      return this.$device.isMobileOrTablet ? 'greedy' : 'auto'
    },

    currentZoom() {
      return (this.map && this.map.getZoom()) || this.minZoom
    },
  },

  watch: {
    shouldMount: function (shouldMountNow) {
      if (shouldMountNow && !this.map) {
        this.initMap()
      }
    },

    items: function () {
      this.openedMarker.active = false
      this.populateMarkers()
    },

    hoveredId: function (newVal) {
      this.updateHoveredMarker(newVal)
    },
  },

  mounted() {
    if (this.shouldMount) {
      this.emitBoundsUpdated = throttle((bounds) => {
        this.$emit('bounds-updated', bounds)
      }, 300)

      this.initMap()
    }
  },

  beforeUnmount() {
    if (this.map) {
      this.map = false
    }
  },

  methods: {
    openCard({ marker, markerEl }) {
      this.openedMarker.active = false
      this.openedMarker.marker = marker

      this.clearHoveredMarker()

      this.markers[marker.Id].zIndex = 1
      this.markers[marker.Id]?.content.classList.add('hover')

      this.popper = createPopper(markerEl, this.$refs.popupEl, {
        onFirstUpdate: () => this.popper.update(),
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: this.$refs.map,
              mainAxis: true,
              altAxis: true,
              padding: 16,
            },
          },
          {
            name: 'offset',
            options: {
              offset: ({ placement }) => {
                if (['top', 'bottom'].includes(placement)) {
                  return [0, 4]
                }
                return [4, 0]
              },
            },
          },
        ],
      })

      this.openedMarker.active = true

      if (this.$device.isMobileOrTablet) {
        this.map.setOptions({
          gestureHandling: 'none',
        })
      }
    },

    closeCard() {
      this.openedMarker.active = false
      if (this.openedMarker.marker?.Id && this.markers[this.openedMarker.marker.Id]) {
        this.markers[this.openedMarker.marker.Id].zIndex = undefined
        this.markers[this.openedMarker.marker.Id]?.content.classList.remove('hover')
      }
      this.popper = null

      if (this.$device.isMobileOrTablet) {
        this.map.setOptions({
          gestureHandling: this.gestureHandling,
        })
      }
    },

    viewportChanged() {
      if (this.getBoundingBox()) this.boundsUpdated()
      this.closeCard()
    },

    async initMap() {
      const { map } = await useGoogleMaps()
      this.map = map(this.$refs.map, {
        mapId: '3ca56b12845f4169',
        center: this.centerPoint,
        zoom: this.defaultZoom,
        maxZoom: this.maxZoom,
        minZoom: this.minZoom,
        disableDefaultUI: true,
        scrollwheel: false,
        clickableIcons: false,
        gestureHandling: this.gestureHandling,
      })
      this.setBoundingBox(this.boundingBox)
      this.map.addListener('dragend', this.viewportChanged)
      this.map.addListener('dblclick', this.viewportChanged)
      this.map.addListener('click', () => {
        this.closeCard()
      })
      this.populateMarkers()
    },

    boundsUpdated(manualSearch) {
      const bounds = this.getBoundingBox()
      this.boundsChanged = manualSearch
        ? false
        : !['north', 'east', 'south', 'west'].every((x) => this.lastBounds[x] === bounds[x])
      this.lastBounds = { ...bounds }

      if (this.searchOnMapMove || manualSearch) this.emitBoundsUpdated(bounds)
    },

    toggleSearchOnMap() {
      this.boundsChanged = this.searchOnMapMove = !this.searchOnMapMove
    },

    updateBoundingBox(bounds, coords) {
      if (!coords) return
      if (bounds.north == null) bounds.north = coords.lat
      if (bounds.south == null) bounds.south = coords.lat
      if (bounds.east == null) bounds.east = coords.lng
      if (bounds.west == null) bounds.west = coords.lng

      bounds.north = Math.max(bounds.north, coords.lat)
      bounds.south = Math.min(bounds.south, coords.lat)
      bounds.east = Math.max(bounds.east, coords.lng)
      bounds.west = Math.min(bounds.west, coords.lng)
    },

    setBoundingBox(bounds) {
      if (!bounds || bounds.north == null || bounds.east == null || bounds.south == null || bounds.west == null) return

      this.map.fitBounds(
        new window.google.maps.LatLngBounds(
          {
            lat: bounds.south,
            lng: bounds.west,
          },
          {
            lat: bounds.north,
            lng: bounds.east,
          },
        ),
        0,
      )

      this.constrainZoom()
    },

    getBoundingBox() {
      const bounds = this.map && this.map.getBounds()
      if (bounds) {
        const northEast = bounds.getNorthEast()
        const southWest = bounds.getSouthWest()

        return {
          east: northEast.lng(),
          north: northEast.lat(),
          south: southWest.lat(),
          west: southWest.lng(),
        }
      }

      return null
    },

    constrainZoom() {
      const zoom = this.map.getZoom()
      if (zoom < this.minZoom) this.map.setZoom(this.minZoom)
      if (zoom > this.maxZoom) this.map.setZoom(this.maxZoom)
    },

    async populateMarkers() {
      if (this.map) {
        const { advancedMarker } = await useGoogleMaps()
        Object.values(this.markers).forEach((marker) => {
          marker.setMap(null)
        })
        const markers = {}
        const bounds = this.boundingBox ?? { north: null, east: null, south: null, west: null }
        if (this.items) {
          this.items?.forEach((item) => {
            const marker = this.buildMarker(item)
            if (!marker || markers[marker.Item.Id]) return
            if (!marker.MarkerIcon && !marker.MarkerText) return
            const coords = {
              lat: marker.Latitude,
              lng: marker.Longitude,
            }
            this.updateBoundingBox(bounds, coords)
            const el = document.createElement('div')
            el.classList.add('map-marker')
            if (marker.MarkerIcon) {
              el.innerHTML = marker.MarkerIcon
              el.classList.add('map-marker-icon')
            }
            else {
              el.innerHTML = marker.MarkerText
              el.classList.add('map-marker-text')
            }
            const mapMarker = advancedMarker({ map: toRaw(this.map), position: coords, content: el })
            mapMarker.addListener('click', () => this.openCard({ marker, markerEl: el }))
            mapMarker.content?.addEventListener('mouseenter', () => {
              mapMarker.zIndex = 2
            })
            mapMarker.content?.addEventListener('mouseleave', () => {
              if (this.openedMarker.marker?.Id !== marker.Item.Id) {
                mapMarker.zIndex = undefined
              }
            })
            markers[marker.Id] = mapMarker
          })
          this.markers = markers
          if (!this.boundingBox?.searched) {
            if (bounds.north != null && bounds.east != null && bounds.south != null && bounds.west != null) {
              this.setBoundingBox(bounds)
            }
            else {
              this.map.setCenter(this.centerPoint)
              this.map.setZoom(this.defaultZoom)
            }
          }
        }
        else {
          this.setBoundingBox(this.boundingBox)
          this.markers = markers
        }
      }
    },

    buildMarker(obj) {
      const rv = obj.Item
      const price = this.priceDisplay({
        value: rv.PreDiscountedAverageNightlyPrice,
        countryCode: rv.Country,
        showCurrencyCode: false,
        round: true,
        internationalPricing: true,
      })

      return {
        Id: rv.Id,
        Item: rv,
        MarkerText: !this.showMarkerIcon ? price : undefined,
        MarkerIcon: this.showMarkerIcon ? rvSecondary : undefined,
        Latitude: Number.parseFloat(rv.Latitude),
        Longitude: Number.parseFloat(rv.Longitude),
      }
    },

    updateHoveredMarker(hoveredId) {
      this.clearHoveredMarker()

      if (hoveredId && this.markers[hoveredId]) {
        this.markers[hoveredId].zIndex = 2
        this.markers[hoveredId]?.content.classList.add('hover')
      }
    },

    clearHoveredMarker() {
      Object.keys(this.markers).forEach((markerId) => {
        if (this.openedMarker.marker?.Id !== markerId) {
          this.markers[markerId].zIndex = undefined
          this.markers[markerId]?.content.classList.remove('hover')
        }
      })
    },

    zoomIn() {
      this.map.setZoom(this.map.getZoom() + 1)
      this.boundsUpdated()
    },

    zoomOut() {
      this.map.setZoom(this.map.getZoom() - 1)
      this.boundsUpdated()
    },
  },
}
</script>

<style lang="scss" scoped>
.search-move-map-btn,
.zoom-buttons {
  position: absolute;
  top: 1.25rem;
  z-index: 4;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
}

.search-checkbox {
  margin: 0.5rem;
  font-size: 0.75rem;
}
.search-move-map-btn {
  left: 1.25rem;

  .zbtn {
    @include regular-weight;
    border-radius: 0.5rem;
    color: getColor('primary-500');
    padding: 0.875rem;
    font-size: 0.75rem;
    line-height: 1;

    @include media-max-size(small) {
      padding: 0.5rem;
    }
  }
}

.zoom-buttons {
  right: 1.25rem;
}

:deep(.gm-style) {
  font-family: inherit;

  .map-marker {
    z-index: 1;
    position: relative;
    cursor: pointer;

    &.map-marker-text {
      background: getColor('primary-500');
      padding: 0.375rem 0.75rem;
      color: #fff;
      border-radius: 1rem;
      @include caption-strong;

      &:hover,
      &.hover,
      &.active {
        z-index: 2;
        background-color: #fff;
        color: getColor('primary-500');
        transition: all linear 50ms;
      }
    }
  }

  .map-marker-icon {
    &:hover,
    &.hover,
    &.active {
      z-index: 2;
    }
  }
}
:deep(.popup) {
  position: absolute;
  z-index: 3;
  font-size: 1rem;
  width: 14.875rem;

  .rv-card {
    display: block;
    background-color: #fff;
    border: 0;
    border-radius: 1rem;
    box-shadow: 4px 4px 24px rgba(3, 46, 90, 0.06);

    .rv-image {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .rv-images {
      margin-bottom: 0;
      border-radius: 1rem 1rem 0 0;
    }

    .rv-image {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .rv-info {
      padding: 1rem;
      margin-top: 0;
    }

    + .arrow {
      display: none !important;
    }
  }
}

.arrow,
.arrow::before {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background: #fff;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

[data-popper-placement^='top'] > .arrow {
  bottom: -0.25rem;
}

[data-popper-placement^='bottom'] > .arrow {
  top: -0.25rem;
}

[data-popper-placement^='left'] > .arrow {
  right: -0.25rem;
}

[data-popper-placement^='right'] > .arrow {
  left: -0.25rem;
}
</style>

<i18n lang="json">
{
  "en": {
    "searchAfterMapMove": "Search as I move the map",
    "searchThisArea": "Search this area"
  },
  "fr": {
    "searchAfterMapMove": "Rechercher en déplaçant la carte",
    "searchThisArea": "Rechercher cette zone"
  }
}
</i18n>
