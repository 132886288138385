<template>
  <div :data-search-loaded="!pending">
    <div class="search-navbar d-none d-md-block">
      <div class="d-flex justify-content-between px-3">
        <SearchFilters />
        <div class="show-map-tablet align-items-center">
          <span class="mr-1">{{ t("showMap") }}</span>
          <ZFormCheckbox
            id="toggle-map-tablet"
            v-model="showMapTablet"
            :checked="showMapTablet"
            toggle
          />
        </div>
        <div class="show-map-desktop mr-1 align-items-center">
          <span class="mr-1">{{ t("showMap") }}</span>
          <ZFormCheckbox
            id="toggle-map-desktop"
            v-model="showMapDesktop"
            :checked="showMapDesktop"
            toggle
          />
        </div>
      </div>
    </div>

    <BannersHighlight />

    <div
      :class="[
        'search-results-wrapper',
        {
          'with-map-mobile': showMapMobile,
          'with-map-tablet': showMapTablet,
          'with-map-desktop': showMapDesktop,
        },
      ]"
    >
      <div class="search-results">
        <slot name="breadcrumbs" />

        <template v-if="!noResultsFound">
          <h1
            v-if="heading !== null"
            class="mb-5"
          >
            {{ heading }}
          </h1>

          <div class="d-flex align-items-center mb-3">
            <h2 class="flex-shrink-0 mr-3 mb-0">
              {{ subtitle }}
            </h2>
            <div
              class="ml-auto"
            >
              <SearchSort />
            </div>
          </div>

          <slot name="preResults" />

          <GridList
            ref="featuredList"
            :results-length="featuredRvs.length"
            :page-size="featuredPageSize"
            :batch-size="batchSize"
            :rows="1"
            hide-no-results
            class="featured-grid"
          >
            <template
              #default="{ visibleResults, loadMore, queueViewableImpression }"
            >
              <CardRv
                v-for="(card, index) in visibleResults"
                :key="card.pregen_id"
                :index="index"
                :search-request-id="featuredResults?.Id || ''"
                :is-loading="pending"
                :rv="featuredRvs?.[index]"
                cta="featured"
                show-featured
                @scrolled-into-view="loadMore(index)"
                @mouseenter="handleCardHover(featuredRvs?.[index]?.Id)"
                @mouseleave="handleCardHover()"
                @visible:rv="queueViewableImpression"
              />
            </template>
          </GridList>
          <GridList
            :results-length="popularRvs.length"
            :page-size="pageSize"
            :batch-size="batchSize"
          >
            <template
              #default="{ visibleResults, loadMore, queueViewableImpression }"
            >
              <CardRv
                v-for="(card, index) in visibleResults"
                :key="card.pregen_id"
                :index="index"
                :search-request-id="popularResults?.Id || ''"
                :is-loading="pending"
                :rv="popularRvs?.[index]"
                cta="popular"
                @scrolled-into-view="loadMore(index)"
                @mouseenter="handleCardHover(popularRvs?.[index]?.Id)"
                @mouseleave="handleCardHover()"
                @visible:rv="queueViewableImpression"
              />
            </template>
          </GridList>

          <div class="mt-4">
            <ZPagination
              :current-page="currentPage + 1"
              :total="totalResults"
              :page-size="pageSize"
              data-testid="search-pagination"
              @change="handlePageChange"
            />
          </div>
        </template>

        <SearchNoResultsFound
          v-else
          @clear-filters="handleClearFilters"
        />
      </div>
      <SearchMap
        :should-mount="showMap"
        :is-results-loading="pending"
        :items="mapResults"
        :hovered-id="hoveredCardId"
        @bounds-updated="handleMapMoved"
      />
    </div>

    <ClientOnly>
      <ZButton
        v-if="!showMapMobile"
        class="show-map-mobile mobile-map-toggles"
        @click="handleToggleMapMobile"
      >
        <fa
          class="mr-1"
          icon="map"
        />
        {{ t("map") }}
      </ZButton>
      <ZButton
        v-else
        variant="white"
        class="hide-map-mobile mobile-map-toggles"
        @click="handleToggleMapMobile"
      >
        <span class="swipe-up" /><span>{{ t("viewListingPage") }}</span>
      </ZButton>
    </ClientOnly>
  </div>
</template>

<script setup>
import {
  drivable as motorhomeRvTypes,
  towable as travelTrailerTypes,
} from '~/assets/data/rvtypes'

import { SEARCH_SORT_TYPES } from '~/constants/search'
import { createRequestKey } from '~/utils/generators'
import getRvCategoryByType from '~/lib/getRvCategoryByType'
import { getNightlyRate, getOriginalNightlyRate } from '~/lib/rvs/index'
import { IMAGE_QUALITY } from '~/constants/image'
import { isBudgetFriendlySelected } from '~/lib/search'

const props = defineProps({
  preventSearchParametersUpdate: {
    type: Boolean,
    default: false,
  },

  heading: {
    type: String,
    default: null,
  },

  subheading: {
    type: String,
    default: null,
  },

  featuredPageSize: {
    type: Number,
    default: 5,
  },

  pageSize: {
    type: Number,
    default: 30,
  },
})

const { t } = useI18n({
  useScope: 'local',
})

const { $search } = useNuxtApp()
const runtimeConfig = useRuntimeConfig()

const {
  popularResults,
  popularRvs,
  featuredResults,
  featuredRvs,
  totalResults,
  noResultsFound,
  mapResults,
  pending,
  batchSize,
  currentPage,
  handlePageChange,
  handleClearFilters,
} = await useSearchData($search)

const {
  showMap,
  showMapMobile,
  showMapTablet,
  showMapDesktop,
  handleToggleMapMobile,
  handleMapMoved,
  hoveredCardId,
  handleCardHover,
} = useMap($search)

useSearchTracking(
  runtimeConfig,
  $search,
  featuredResults,
  popularResults,
  currentPage,
)

const subtitle = computed(() => {
  if (props.subheading) {
    return props.subheading
  }

  return t('rvRentals')
})

/**
 * This composable relates to the search map.
 */
function useMap($search) {
  const showMapMobile = ref(false)
  const showMapTablet = ref(false)
  const showMapDesktop = ref(true)
  const showMap = computed(
    () => showMapMobile.value || showMapTablet.value || showMapDesktop.value,
  )
  const hoveredCardId = ref(null)

  const handleCardHover = (id = null) => (hoveredCardId.value = id)

  /**
   * Handles toggling the map on or off for mobile.
   */
  const handleToggleMapMobile = () => {
    showMapMobile.value = !showMapMobile.value

    window.scrollTo(0, 0)
  }

  /**
   * Handles moving the map by updating the map bounds.
   */
  const handleMapMoved = (bounds) => {
    $search.updateBounds(bounds)
  }

  /**
   * Watch for changes to the breakpoint and update the map visibility for
   * desktop.
   */
  // watch(isLargeBreakpoint, (isLargeBreakpointValue) => {
  //   showMapDesktop.value = isLargeBreakpointValue
  // })

  return {
    showMap,
    showMapMobile,
    showMapTablet,
    showMapDesktop,
    handleToggleMapMobile,
    handleMapMoved,
    hoveredCardId,
    handleCardHover,
  }
}

/**
 * This composable handles all the data fetching.
 */
async function useSearchData($search) {
  const { $captureError } = useNuxtApp()
  const { isMobile } = useDevice()
  const { rvApi } = useApi()
  const currentPage = ref(0)
  const { isLoggedIn } = useAuthentication()
  const { selectedCurrencyCode } = useCurrency()

  const searchParams = computed(() => $search.searchParametersToApiRequest())

  const params = computed(() => ({
    ...searchParams.value,
    FeaturedCurrentPage: 0,
    FeaturedPageSize: props.featuredPageSize,
    PageSize: props.pageSize,
    CurrentPage: currentPage.value,
    IncludeFeatured:
      currentPage.value === 0
      && searchParams.value.SortOrder === SEARCH_SORT_TYPES.Recommended,
    CurrencyCode: !isLoggedIn.value ? selectedCurrencyCode.value : undefined,
  }))

  const searchKey = computed(() => createRequestKey('searchVueComponent', JSON.stringify(params.value)))

  const { data, pending, error } = await rvApi.getRvUnifiedSearch(searchKey, params)
  if (error.value) {
    $captureError(error.value)
  }

  const popularResults = computed(() => data.value?.PopularRVs || {})
  const popularRvs = computed(() => data.value?.PopularRVs?.ListRVs || [])
  const featuredResults = computed(() => data.value?.FeaturedRVs || {})
  const featuredRvs = computed(() => data.value?.FeaturedRVs?.ListRVs || [])

  const totalResults = computed(() => popularResults.value?.TotalRVs || 0)
  const batchSize = computed(() => (isMobile ? 2 : 6))
  const noResultsFound = computed(
    () =>
      featuredResults?.value?.TotalRVs === 0
      && popularResults?.value?.TotalRVs === 0,
  )

  const mapResults = computed(() => {
    const results = []
    popularRvs.value?.map((rv) => {
      rv.SearchId = ''
      results.push({
        Item: rv,
      })
    })
    featuredRvs.value?.map((rv) => {
      rv.SearchId = ''
      results.push({
        Item: rv,
      })
    })
    return results
  })

  /**
   * Handles changing pages.
   */
  const handlePageChange = async (page) => {
    currentPage.value = page - 1 || 0
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleClearFilters = () => {
    $search.updateFilters({})
  }

  /**
   * Handles the search params being updated.
   */
  const searchParametersUpdated = () => {
    if (
      props.preventSearchParametersUpdate
    ) {
      return
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
    currentPage.value = 0
    $search.updateRoute()
  }

  // Watch for changes to the search params.
  watch($search.parameters, () => searchParametersUpdated(), { deep: true })

  watch(selectedCurrencyCode, () => {
    if ($search.parameters.filters.rvPrice?.min || $search.parameters.filters.rvPrice?.max) {
      searchParametersUpdated()
    }
  })

  return {
    popularResults,
    popularRvs,
    featuredResults,
    featuredRvs,
    totalResults,
    noResultsFound,
    mapResults,

    pending,

    batchSize,
    currentPage,

    handlePageChange,
    handleClearFilters,
  }
}

/**
 * This composable takes care of tracking events on the search page.
 */
function useSearchTracking(
  runtimeConfig,
  $search,
  featuredResults,
  popularResults,
  currentPage,
) {
  const { trackListingSetPresented, trackSearchViewed } = useTracking()
  const { $moment } = useNuxtApp()
  const { routeBaseName } = useBaseName()
  const { isRvFavourited } = useFavouriteRVs()
  const { getImageUrl } = useImageUrl()

  const trackListingSet = ({
    results,
    cta,
    listingPageSize,
    listPageNumber,
  }) => {
    trackListingSetPresented({
      pageSource: routeBaseName.value,
      listingPageNumber: listPageNumber,
      listingPageSize: listingPageSize,
      cta: cta,
      listings: results?.ListRVs?.map((rv, index) => {
        return {
          listPosition: index + 1,
          isFavourite: Boolean(isRvFavourited(rv.Id)),
          heroImage: getImageUrl({ path: rv?.Photos?.[0].Path, quality: IMAGE_QUALITY.medium }) ?? '',
          rvUrl: useAbsoluteUrl(generateRvPath(rv?.AliasName)),
          rvId: rv.Id,
          rvName: rv.RVName,
          path: rv?.Photos?.[0].Path,
          rentalType: getRvCategoryByType(rv.RVType) || 'Unknown',
          listingType: rv.RVType,
          isInstantBook: rv.InstabookOwnerOptedIn,
          isFeatured: rv.IsFeatured,
          hasDelivery: rv.HasDelivery,
          currency: lookupCountryCodeToCurrencyCode(rv.Country),
          distanceKm: rv.Distance,
          listingCity: rv.City,
          listingRegion: rv.State,
          listingCountry: rv.Country,
          listingSleepingSpots: rv.Guests,
          starRating: rv.AverageRating,
          numReviews: rv.NumberOfReview,
          reviewsShown: rv.NumberOfReview > 0,
          distanceUnit: countryCodeToDistanceUnit(rv.Country),
          smartNightlyRate: rv.SmartPricingPercentage > 0 ? getNightlyRate(rv) : null,
          nightlyRate: getOriginalNightlyRate(rv),
          undatedTripStart: $search.parameters.dates.dates.start ?? undefined,
          undatedTripEnd: $search.parameters.dates.dates.end ?? undefined,
        }
      }),
      searchCity: $search.parameters.location?.city?.long_name || '',
      searchRegion: $search.parameters.location?.region?.long_name || '',
      searchCountry: $search.parameters.location?.country?.long_name || '',
      listingIds: results?.ListRVs?.map((r) => r.Id),
      searchFilters: {
        delivery: $search.parameters.filters.delivery,
        instantBook: $search.parameters.filters.instantBook,
        listingTypes: Object.keys({
          ...$search.parameters.filters.drivable,
          ...$search.parameters.filters.towable,
        }).filter((key) => {
          return (
            $search.parameters.filters.drivable?.[key] === true
            || $search.parameters.filters.towable?.[key] === true
          )
        }),
        minPrice: $search.parameters.filters.minPrice ?? 0,
        maxPrice: $search.parameters.filters.maxPrice ?? 500,
        amenities: $search.parameters.filters.amenities ?? [],
      },
    })
  }

  const trackSearch = (searchResults) => {
    const searchLocation = $search.parameters.location
    const searchedRvTypes = Object.keys(
      $search.parameters.filters.drivable || [],
    )
      .filter((x) => $search.parameters.filters.drivable?.[x])
      .concat(
        Object.keys($search.parameters.filters.towable || []).filter(
          (x) => $search.parameters.filters.towable?.[x],
        ),
      )
      .concat($search.parameters.filters.rvCottage ? ['RVCottage'] : [])

    // Todo: not able to fully Implement Ts Type until this file uses Ts
    // Filter null values
    const params = Object.fromEntries(
      Object.entries({
        /** required attributes */
        pageSource: routeBaseName.value,
        numResults: searchResults?.TotalRVs ?? 0,
        numResultsDisplayed: searchResults?.ListRVs?.length ?? 0,
        searchPage: currentPage.value ? parseInt(currentPage.value) + 1 : 1,
        showMap: showMap.value,

        /** optional attributes */
        googlePlaceId: searchLocation?.placeId,
        startDate: $search.parameters.dates.dates.start,
        endDate: $search.parameters.dates.dates.end,
        searchAddress: searchLocation?.fullName,
        searchCountry: searchLocation?.country?.long_name,
        searchRegion: searchLocation?.region?.short_name,
        searchCity: searchLocation?.city?.long_name,
        searchLatSW: $search.parameters.bounds.south,
        searchLonSW: $search.parameters.bounds.west,
        searchLatNE: $search.parameters.bounds.north,
        searchLonNE: $search.parameters.bounds.east,

        // this doesn't come from the querystring, as we base it off the searched location from geocoding
        searchLat: searchLocation?.center?.lat,
        searchLng: searchLocation?.center?.lng,

        /** @TODO: RVZ-13241 - these should be pulling from the map, not the searchLocation */
        viewLatSW:
          searchLocation?.bounds?.south ?? $search.parameters.bounds.south,
        viewLonSW:
          searchLocation?.bounds?.west ?? $search.parameters.bounds.west,
        viewLatNE:
          searchLocation?.bounds?.north ?? $search.parameters.bounds.north,
        viewLonNE:
          searchLocation?.bounds?.east ?? $search.parameters.bounds.east,

        destinationTypes: searchLocation?.types || [],
        adults: $search.parameters.guests.adults,
        children: $search.parameters.guests.children,
        instant: $search.parameters.filters.instantbook ?? false,
        delivery: $search.parameters.filters.delivery ?? false,
        priceMin: $search.parameters.filters.rvPrice?.min,
        priceMax: $search.parameters.filters.rvPrice?.max,
        amenities: Object.keys(
          $search.parameters.filters.amenities ?? [],
        ).filter((x) => $search.parameters.filters.amenities?.[x])
          .concat($search.parameters.filters.petFriendly ? ['PetFriendly'] : [])
          .filter((x) => x),
        minLength: $search.parameters.filters.rvLength?.min,
        maxLength: $search.parameters.filters.rvLength?.max,
        minWeight: $search.parameters.filters.rvWeight?.min,
        maxWeight: $search.parameters.filters.rvWeight?.max,
        rvType: searchedRvTypes,
        requestId: '', // requestId no longer exists
        minRvYear: $search.parameters.filters.rvYear?.min
          ? Number($search.parameters.filters.rvYear.min)
          : undefined,
        maxRvYear: $search.parameters.filters.rvYear?.max
          ? parseInt($search.parameters.filters.rvYear.max)
          : undefined,
        rvManufacturer: $search.parameters.filters.rvBrand,
        listingType:
          searchedRvTypes?.length === motorhomeRvTypes.length
          && searchedRvTypes.every((x) => motorhomeRvTypes.includes(x))
            ? 'motorhome'
            : searchedRvTypes?.length === travelTrailerTypes.length
            && searchedRvTypes.every((x) => travelTrailerTypes.includes(x))
              ? 'traveltrailer'
              : null,
        festivalAndEventsFriendly:
          $search.parameters.filters.festivalFriendly ?? false,
        experienceNotRequired:
          $search.parameters.filters.experienceNotRequired ?? false,
        budgetFriendly: isBudgetFriendlySelected(
          $search.parameters.filters.rvPrice?.min,
          $search.parameters.filters.rvPrice?.max,
        ),
        midweekDeals: isMidweekSelected(
          $search.parameters.dates.dates.start,
          $search.parameters.dates.dates.end,
        ),
        isMenuFilterApplied: $search.isMenuFilterApplied.value,
      }).filter(([key, value]) => key != null && value != null && value !== ''),
    )

    if (params.startDate && params.endDate) {
      if (!params.tripLength) {
        params.tripLength = $moment(params.endDate).diff(
          $moment(params.startDate),
          'days',
        )
      }

      params.daysOut = $moment(params.startDate).diff(
        $moment().startOf('day'),
        'days',
      )
    }

    const additionalParams = searchResults.ListRVs.map((x) => ({ id: x.Id }))
    trackSearchViewed({ params: params, additionalParams: additionalParams })
    $search.resetMenuFilter()
  }

  // Watch featured and popular RV results and track them.
  // We do this client-side only.
  watch(
    featuredResults,
    (newVal) => {
      if (
        import.meta.client
          && newVal
      ) {
        trackListingSet({
          cta: 'featured',
          results: newVal,
          listingPageSize: props.featuredPageSize,
          listPageNumber: 1,
        })
      }
    },
    { immediate: true },
  )

  watch(
    popularResults,
    (newVal) => {
      if (
        import.meta.client
          && newVal
      ) {
        trackListingSet({
          cta: 'popular',
          results: newVal,
          listingPageSize: props.pageSize,
          listPageNumber: 1,
        })
      }

      if (import.meta.client && newVal) {
        trackSearch(newVal)
      }
    },
    { immediate: true },
  )
}
</script>

<style lang="scss" scoped>
.mobile-map-toggles {
  @include media-min-size(large) {
    display: none;
  }
}

.show-map-tablet, .show-map-desktop {
  @include caption;
}
</style>

<i18n src="~/locales/common/map.json" lang="json" />

<i18n src="~/locales/common/search/results.json" lang="json" />

<i18n lang="json">
{
  "en": {
    "rvRentals": "RV Rentals"
  },
  "fr": {
    "rvRentals": "Location de VR"
  }
}
</i18n>
